<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Last Question - After taking this course, do you feel like you have gained some ability to
        think ________________ ? Check as many as apply to <b>you</b>.
      </p>

      <p v-for="option in options" :key="'pt-1-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.input1" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>

      <p class="mt-3">
        Thank you for your time and efforts this term. It has been a real pleasure. Have a GREAT
        BREAK and we'll see you again soon!
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'OleMissSurveyQ19',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
      },
      options: [
        {text: 'more critically', value: 'one'},
        {text: 'more historically', value: 'two'},
        {text: 'more scientifically', value: 'three'},
        {text: 'more mathematically', value: 'four'},
        {text: 'more clearly', value: 'five'},
        {text: 'more accurately', value: 'six'},
        {text: 'more deeply', value: 'seven'},
        {text: 'more logically', value: 'eight'},
        {text: 'more fairly', value: 'nine'},
        {text: 'more empathetically', value: 'ten'},
        {text: 'none of the above', value: 'none'},
      ],
    };
  },
};
</script>
<style scoped></style>
